import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/sections/quota/management', config);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/sections/' + id + '/quota', formData);
}

const exportExcel = ()=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/sections/quota/management/excel-export', {responseType:"arraybuffer"});
}

export default {
    getAll,
    update,
    exportExcel
}
